<template>
  <div class="image-container">
    <img :src="fullImageUrl" alt="Shared Image" class="responsive-image" />
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fullImageUrl: ''
    };
  },
  created() {
    this.fullImageUrl = this.imageUrl.startsWith('https') ? this.imageUrl : `${window.location.origin}/${this.imageUrl}`;
    console.log('Full Image URL:', this.fullImageUrl); 
    document.dispatchEvent(new Event('render-event')); // Debug log
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  metaInfo() {
    return {
      title: 'Check out this image',
      meta: [
        { property: 'og:title', content: 'Check out this image' },
        { property: 'og:description', content: 'Check out this amazing image I found in briks!' },
        { property: 'og:image', content: this.fullImageUrl },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Check out this image' },
        { name: 'twitter:description', content: 'Check out this amazing image I found in briks!' },
        { name: 'twitter:image', content: this.fullImageUrl },
        { name: 'twitter:url', content: window.location.href },
      ],
    };
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa; /* Optional background color */
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd; /* Optional border */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
}
</style>
